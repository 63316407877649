/* You can add global styles to this file, and also import other style files */
@import "../node_modules/bootstrap/dist/css/bootstrap.css";


html, body { height: 100%; scroll-behavior: smooth; }
body { 
    margin: 0; 
    font-family: Caudex;
    font-style: normal;
    font-weight: normal;
    /* letter-spacing: 0.1em; */
}

.container, .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
    padding: 0;
}